import React, { useState,useEffect,useCallback } from 'react';

//import { ReactComponent as BuilderOutput } from '../img/maze/builder-output.svg';
import BuilderOutput from '../img/maze/builder-output.svg';
import BuilderClassAssign from '../img/maze/builder-class-assign.svg';
import SolverInOut from '../img/maze/solver-in-out.svg';
import Recursion from '../img/maze/recursion.svg';
import Iteration from '../img/maze/iteration.svg';
import SplitSolve from '../img/maze/split-solve.svg';

const Maze = () => {
	
	// Maze Builder Inputs
//	const colsRef = useRef(5); // Uncontrolled form allows for input to be blank without resizing maze
//	const rowsRef = useRef(5);
//	const perRef = useRef(50);
	const [cols, setCols] = useState(10);
	const [rows, setRows] = useState(5);
	const [per, setPer] = useState(60);
	const [built,setBuilt] = useState(0);
	// Maze Builder Outputs
	const [cellList,setCellList] = useState([]);
	const [cellDict,setCellDict] = useState({});
	// Maze Solver Outputs
	const [solved,setSolved] = useState(0);
	const [paths,setPaths] = useState({});
	
	// Redraw Empty Maze on Resize
	useEffect( () => {
		setBuilt(0);
		setSolved(0);
		setStart(-1);
		setFinish(-1);
		setSolution([]);
		setPaths({});
		let newList = ["1001","1000"]; 
		if (cols < 3){
			setCols(3); 
		}
		if (rows < 3){
			setRows(3);
		}
		// ----- SIZE LIMITER DUE TO FAILURE ABOVE 18x17 SIZE ----- //
		if (cols > 17){
			setCols(17); 
		}
		if (rows > 17){
			setRows(17);
		}
		// ----- ----- //
		const cellNum = cols*rows;
		for (let j = 3; j <= cellNum; j++){ // Build Outlines for Blank Maze when Resized
			const newCell = ["0","0","0","0"];
			if (j%cols === 0){ newCell[1] = "1"; }
			if (j%cols === 1){ newCell[3] = "1"; }
			if (j <= cols){ newCell[0] = "1"; }
			if (j > (cellNum-cols)){ newCell[2] = "1"; }
			newList.push(newCell.join(''));
		}
		setCellList(newList);
	},[cols,rows]);
	
	// --- Google Cloud Function Requests --- //
	// Maze Builder
//	const buildRequestOptions = {
//	    method: 'POST',
//	    headers: { 'Content-Type': 'application/json' },
//	    body: JSON.stringify({ "cols":cols,"rows":rows,"per":per })
//	};
	const [buildButton,setBuildButton] = useState('Build');
	const buildUrl = "https://us-central1-mclinn.cloudfunctions.net/maze-create";
	const buildMaze = async () => {
		setBuildButton('Building...');
		setStart(-1);
		setFinish(-1);
		try {
			const response = await fetch(buildUrl,{method:'POST',headers:{'Content-Type':'application/json'},body:JSON.stringify({"cols":cols,"rows":rows,"per":per})});
			const json = await response.json();
			setCellList(json["cellList"]);
			setCellDict(json["cellDict"]);
			setBuildButton('Build');
			setSolved(0);
			setBuilt(1);
			setSolution([]);
			setPaths({});
				} catch (error) {
					console.log("error",error);
					setBuildButton('Error - Try Again');
				}
		};
	// Maze Solver
//	const solveRequestOptions = {
//	    method: 'POST',
//	    headers: { 'Content-Type': 'application/json' },
//	    body: JSON.stringify({"cells":cellDict})
//	};
	const [solveButton,setSolveButton] = useState('Solve');
	const solveUrl = "https://us-central1-mclinn.cloudfunctions.net/maze-solve";
	const solveMaze = async () => {
		if (!(solved === 1)){ //&& !(Object.keys(cellDict).length === 0)){
			setSolved(2);
			try {
				const response = await fetch(solveUrl,{method:'POST',headers:{'Content-Type':'application/json'},body:JSON.stringify({"cells":cellDict})});
				const json = await response.json();
				setSolved(1);
				setPaths(json["paths"]);
					} catch (error) {
						console.log("error",error);
						setSolved(-1);
					}
		}
	};
	
	// Set Language in Solve Button
	useEffect( () => {
		if (solved === 2){
			setSolveButton('Solving...');
		} else if (solved === 1){
			setSolveButton('Solved');
		} else {
			setSolveButton('Solve');
		}
	},[solved]);
	
	// Path Builder Inputs
	const [start, setStart] = useState(-1);
	const [finish, setFinish] = useState(-1);
	const [solution, setSolution] = useState([]);
	const [mazeBack,setMazeBack] = useState('#fff');
	
	// Build Path (if Solved)
	const buildPath = e => {
		if (solved === 1){ // Only run if solution exists
			const key = parseInt(e.target.id);
			if (start === key){ // if re-selecting Start -- reset Path
				setStart(-1);
				setFinish(-1);
				setSolution([]);
			}
			else if (start === -1){ // if Start not set -- set Start
				setStart(key);
			} else { // otherwise -- set/reset Finish
				const pathKey = String(parseInt(start)+1)+"-"+String(parseInt(key)+1);
				if (pathKey in paths){
					setFinish(key);
					setSolution(paths[pathKey])
				} else {
					setMazeBack('#f88');
					setTimeout(() => setMazeBack('#fff'),500);
				}
			}
		}
	};
	const [width,setWidth] = useState(window.innerWidth);
	const updateWidth = useCallback(() => {
		let winWidth = window.innerWidth;
		if (winWidth > 1150 ){ winWidth = 1150; }
		setWidth(winWidth/cols*0.8);
		//console.log(width);
	},[cols]);
	useEffect(() => {
		window.addEventListener("resize",updateWidth);
		return () => window.removeEventListener("resize",updateWidth);
	});
	
	useEffect(() => {
		updateWidth();
	},[cols,updateWidth]);
	
	
	const [opacity,setOpacity] = useState(1)
	
	// Maze Size Change Handlers
	const sizeChange = (e,count) => {		
		if (e === "leftRow"){
			if(!(count === 3)){
				setOpacity(0);
				setRows(count - 1);
				setTimeout(() => setOpacity(1),200);
			}
		} else if (e === "rightRow"){
			if(!(count === 17)){
				setOpacity(0);
				setRows(count + 1);
				setTimeout(() => setOpacity(1),200);
			}
		} else if (e === "leftCol"){
			if(!(count === 3)){
				setOpacity(0);
				setCols(count - 1);
				setTimeout(() => setOpacity(1),200);
			}
		} else if (e === "rightCol"){
			if(!(count === 17)){
				setOpacity(0);
				setCols(count + 1);
				setTimeout(() => setOpacity(1),200);
			}
		} else if (e === "leftPer"){
			if(!(count === 0)){
				setPer(count - 10);
			}
		} else if (e === "rightPer"){
			if(!(count === 100)){
				setPer(count + 10);
			}
		}
	};
	
	// Build Maze
	const Maze = cellList.map((cell,index) => <div id={index} key={index} className={`c${cell} ${ (solution.includes(index+1) && !(index === start) && !(index === finish)) ?  "path" : ""} ${ (start === index) ?  "start" : ""} ${ (finish === index) ?  "finish" : ""}`} onClick={e => buildPath(e)} style={{ width:`${width}px`,height:`${width}px`,opacity:`${opacity}` }}></div>);
	
	// Collapsed/Expanded State
	const [expanded,setExpanded] = useState(0);
	const expandControl = e => {
		if (expanded === e){
			setExpanded(0);
		} else {
			setExpanded(e);
		}
	}
	
	const expandScroll = (e) => {
		setTimeout(() => {e.target.scrollIntoView();},10);
	};
	
  return (
    <>
	  <div className="span2">
	  	<div id="inputs">
		  	<div className="i1">
	  			<h6 className="arrows" onClick={() => sizeChange("leftRow",rows)}>&#10094;</h6>
		 	 	<h6 className="inputsInner">{rows}</h6>
				<h6 className="arrows" onClick={() => sizeChange("rightRow",rows)}>&#10095;</h6>
			</div>
	  		<p className="i2">Rows</p>
		  	<div className="i3">
	  			<h6 className="arrows" onClick={() => sizeChange("leftCol",cols)}>&#10094;</h6>
		 	 	<h6 className="inputsInner">{cols}</h6>
				<h6 className="arrows" onClick={() => sizeChange("rightCol",cols)}>&#10095;</h6>
			</div>
	  		<p className="i4">Columns</p>
		  	<div className="i5">
	  			<h6 className="arrows" onClick={() => sizeChange("leftPer",per)}>&#10094;</h6>
		 	 	<h6 className="inputsInner">{per}</h6>
				<h6 className="arrows" onClick={() => sizeChange("rightPer",per)}>&#10095;</h6>
			</div>
			<p className="i6">Wall Chance (%)</p>
		</div>
	  	<div id="buttons">
	  		<div><div className="button"><h6 className="" onClick={() => buildMaze()}> {buildButton} </h6></div></div>
	  		<div><div className={(solved === 1) ? "complete" : (built === 1) ? "button" : "incomplete"}><h6 className="" onClick={() => solveMaze()}> {solveButton} </h6></div></div>
	  	</div>
	  	{(Object.keys(paths).length > 0) ? <h6 className="text" style={{ textAlign:"center",marginBottom:"1rem",width:"100%" }}>Total Paths: {Object.keys(paths).length}</h6> : <></>}
	  	<div id="maze" style={{ gridTemplateColumns:`repeat(${cols}, 1fr)`,border:`5px solid ${mazeBack}`}}>
	  		{Maze}
		</div>
		<h6 className="text" style={{ textAlign:"center",marginTop:"1rem",width:"100%" }}>{(solution.length > 0) ? `Path Length: ${solution.length}` : ""}</h6>
	  </div>
	  <div className="span2">
			<p className="txt" style={{ textAlign:"center" }}>
				{"This demo constructs a randomly generated maze (or labyrinth) from select user inputs and then returns a shortest path between all accessible cells within that maze. The following sections explain my approach in solving and implementing both challenges."}
			</p>
			<h1>{"Maze Builder"}</h1> 										{/* --- --- */}
			<div className="section" onClick={(e) => {expandControl(1);expandScroll(e);}}> 		{/* --- */}
				<h2>{"Inputs/Outputs"}</h2>
				<h2 className={(expanded === 1) ? "arrow-e" : "arrow-c"}>{">"}</h2>
			</div>
			<div className={(expanded === 1) ? "section-e" : "section-c"}>
				<p className="txt">
					{"Rows and columns were required inputs for any user interactivity. The percent chance a wall is placed and a wall limit per cell were optional. I chose to allow the user to change the percent chance a wall will be placed, but to keep the wall limit at two per cell to limit the chance of cell isolation. The Maze Builder, however, is designed to easily accept wall limit as a user input."}
				</p>
				<p className="txt">
					{"The output is purposefully redundant to reduce processing time of the Maze Solver. It returns a list of cell 'types' and an object that gives the open connections for each cell, so either could be used to determine the other. The list is passed to the front-end to visually build the maze, and the object is sent to the Solver as its base for path creation."}
				</p>
				<img className="svg-img" src={BuilderOutput} alt="Maze Builder Output" />
				<p className="txt caption">
					{"4x4 output gives a cell type list ['1001','1100',...] and a cell connections object {'1':[2,5],'2':[1,6],...} "}
				</p>
			</div>
			<div className="section" onClick={(e) => {expandControl(2);expandScroll(e);}}> 		{/* --- */}
				<h2>{"Walls"}</h2>
				<h2 className={(expanded === 2) ? "arrow-e" : "arrow-c"}>{">"}</h2>
			</div>
			<div className={(expanded === 2) ? "section-e" : "section-c"}>
				<p className="txt">
					{"Walls exist within each cell instead of between them. This requires tracking of adjacent cells during the build, but it helps manage cell wall limits and eases visualization. Wall placement is determined by three factors before random wall generation can occur: cell location, adjacent cells, and wall limit."}
				</p>
				<p className="txt">
					{"As shown in the previous section, cells have a type based on its walls ([top,right,bottom,left]) that begins empty ([0,0,0,0]) and is filled in as each cell is 'built'. The maze is built left to right across each row in succession, and the cells are numbered as such. So the top and left walls are based on prior assignment, solving adjacent wall tracking and leaving just the right and bottom walls to be determined."}
				</p>
				<p className="txt-c">{"Borders"}</p>
				<p className="txt">
					{"Cell location is tracked to determine exterior walls using the row and column inputs. If a cell's number places it in the top row, the top of the cell is set to a wall. The bottom row is established similarly. The left and right walls are based on modulo value. Without any remainder between cell number and column nunmber, that cell is on the right. If it's 1, then that cell is the first of a new row and lies on the left side of the maze."}
				</p>
				<p className="txt-c">{"Wall Generation"}</p>
				<p className="txt">
					{"A counter increments as each wall is assigned to a cell. If this counter is less than the wall limit input, a random number 0-100 is generated. If it's less than the wall chance percentage input a wall is placed at this location. This is repeated for all unassigned cells that aren't a border."}
				</p>
				<div className="comment">
					<p className="txt">
						{"Randomness is limited by assigning walls in a set direction. In current form, with a wall generation chance of 100%, the right wall will always exist, leaving the bottom open unless no wall limit is set. A random number generator with a 50% split could be used to decide which wall, right or bottom, is assigned first."}</p>
					<p className="txt">
						{"If cell assignment order was also randomized, a master list of random cell order would determine cell allocation, and this second random number generator could be compared against 25% sections to decide wall assignment order. Whether a neighboring cell's walls had already been set could be discovered based on relative index between both cell numbers within the master allocation list."}
					</p>
				</div>
				<div className="comment">
					<p className="txt">
						{"A problem presents itself for the last cell in the maze. All of its walls are predetermined, so care must be taken for the cells above and to the left. For example, with the wall limit at 2, those walls must always remain open since the last cell is a corner. If the previous cells assigned one of those as a wall that would be in violation of the cell limit, but would circumvent the counter."}
					</p>
				</div>
			</div>
			<div className="section" onClick={(e) => {expandControl(4);expandScroll(e);}}> 		{/* --- */}
				<h2>{"Implementation"}</h2>
				<h2 className={(expanded === 4) ? "arrow-e" : "arrow-c"}>{">"}</h2>
			</div>
			<div className={(expanded === 4) ? "section-e" : "section-c"}>
				<p className="txt">
					{"The maze container element is a CSS Grid. Each cell is an element within the grid, and as the user alters the number of columns the grid is adjusted to reflect this change. The cell list is also set to a blank maze upon any row or column change. Border styling for each possible cell type is already built in the CSS stylesheet, and the appropriate class is assigned to each cell element as the cell list is returned from the Maze Builder."}
				</p>
				<img className="svg-img" src={BuilderClassAssign} alt="Maze Class Assign" />
				<p className="txt caption">
					{"The container grid width is set before the Builder is called. The returned cell-type list sets each cell's class, which controls wall placements."}
				</p>
			</div>
			<div className="section" onClick={(e) => {expandControl(5);expandScroll(e);}}> 		{/* --- */}
				<h2>{"Sizing & Visibility"}</h2>
				<h2 className={(expanded === 5) ? "arrow-e" : "arrow-c"}>{">"}</h2>
			</div>
			<div className={(expanded === 5) ? "section-e" : "section-c"}>
				<p className="txt">
					{"Functionality, especially on smaller screens, required placing a limit on minimum cell size. The consequence, however, meant exceeding screen width at a certain column number, so either a side-scrolling solution or a limit on column number was needed. Limiting column number solved two problems; it kept the look simple and cosistent regardless of screen size, and it hid a Maze Solver limitation"}
				</p>
				<p className="txt">
					{"The return packet size of GCloud Functions is limited, so a Solver solution exceeding that limit would use computing resources without returning any solution. Removing all walls resulted in the largest nominal Maze Solver return (please see the below sections on how the Solver works). Using a square maze, I found the maximum row and column value that would still always work. Luckily this value, 17, corresponded closely with my ideal minumum functional cell size. I changed the mechanism used to set row and column values from an open input to up/down arrows. This decreased input handling and made for easily controlled size transitions."}
				</p>
			</div>
			<h1>{"Maze Solver"}</h1> 										{/* --- --- */}
			<div className="section" onClick={(e) => {expandControl(6);expandScroll(e);}}> 		{/* --- */}
				<h2>{"Inputs/Outputs"}</h2>
				<h2 className={(expanded === 6) ? "arrow-e" : "arrow-c"}>{">"}</h2>
			</div>
			<div className={(expanded === 6) ? "section-e" : "section-c"}>
				<p className="txt">
					{"The Solver takes the Maze Builder's object output that lists each cell's immediate open connections. It outputs an object containing a single, shortest path between every possible cell connection. Path start and end points make up each key for easy recall in the front-end."}
				</p>
				<img className="svg-img" src={SolverInOut} alt="Solver Input/Output" />
			</div>
			<div className="section" onClick={(e) => {expandControl(7);expandScroll(e);}}> 		{/* --- */}
				<h2>{"Iteration vs Recursion"}</h2>
				<h2 className={(expanded === 7) ? "arrow-e" : "arrow-c"}>{">"}</h2>
			</div>
			<div className={(expanded === 7) ? "section-e" : "section-c"}>
				<p className="txt">
					{"The Solver searches for a new or shorter path between two cells, branching out until the entire path tree for each cell is exhausted."}
				</p>
				<p className="txt">
					{"The first version used a depth-first approach with recursion. A function would receive a single path and attempt to branch further based on its final cell's connections. Each new branch would then be passed recursively to the same function. This method would exhaust a single cell's paths before moving on to the next 'starter' cell, resulting in two primary downsides:"}
				</p>
				<p className="txt listitem">
					{"Indirect paths would be overwritten by shorter, direct routes that wouldn't be discovered until later as each branch was explored to its conclusion. This increased solve time greatly, especially in larger mazes."}
				</p>
				<p className="txt listitem">
					{"Recursion is sometimes limited by the language or engine. The Maze Solver is written in Python, for example, which restricts recursion depth to 1000. For larger mazes, this limit was often triggered."}
				</p>
				<p className="txt">
					{"I rewrote the Solver to improve both issues, iterating through new paths as they were added to a stack. This avoided the recursion limit, and it allowed for flexibility in deciding which paths should be evaulated first. A breadth-first approach was used, where new, longer paths were added to the back of the stack, only to be evaluated after all shorter paths were iterated through first."}
				</p>
				<img className="svg-img" src={Recursion} alt="Recursion" />
				<p className="txt caption">
					{"Recursion solves each start point completely before moving on. Here the [1,2,...] paths are added to the solution before [1,5,...] paths are evaluated, requiring replacement of everything past the first two paths."}
				</p>
				<img className="svg-img" src={Iteration} alt="Iteration" />
				<p className="txt caption">
					{"Iterative process adds all 2-length paths, then 3-length, 4-length, etc. Duplicate paths are simply ignored during the solve with no need to compare for length."}
				</p>
			</div>
			<div className="section" onClick={(e) => {expandControl(8);expandScroll(e);}}> 		{/* --- */}
				<h2>{"Interaction"}</h2>
				<h2 className={(expanded === 8) ? "arrow-e" : "arrow-c"}>{">"}</h2>
			</div>
			<div className={(expanded === 8) ? "section-e" : "section-c"}>
				<p className="txt">
					{"The 'Solve' button is disabled until a maze is built. After a solution is successfully returned it is once again disabled to avoid re-solving the same maze."}
				</p>
				<p className="txt">
					{"After the solution is returned, the user is able to visualize each path by selecting the 'start' and 'finish' cells. Those two keys are then used to find the matching path, and all included cells have a class added that marks them as part of the path."}
				</p>
				<p className="txt">
					{"If a new finish cell is desired, the user simply clicks that element, redrawing the path. I thought about creating a 'Path Reset' button to remove both the 'start' and 'finish' markers, but decided that simply re-clicking the existing 'start' cell could serve that purpose. It appeared an intuitive reaction to those trialing the demo, so I decided to forgo the reset button."}
				</p>
				<div className="comment">
					<p className="txt">
						{"As discussed briefly in Maze Builder > Sizing & Visibility, the Maze Builder return is size limited. Currently, each path exists forward and backward. Eliminating this duplication would increase the maze size that can be evaluated, with that duplication either done once the solution is received or avoiding it altogether by searching for paths both 'start-finish' and 'finish-start'."}
					</p>
				</div>
			</div>
			<div className="section" onClick={(e) => {expandControl(3);expandScroll(e);}}> 		{/* --- */}
				<h2>{"Future Improvement"}</h2>
				<h2 className={(expanded === 3) ? "arrow-e" : "arrow-c"}>{">"}</h2>
			</div>
			<div className={(expanded === 3) ? "section-e" : "section-c"}>
				<p className="txt">
					{"Larger solutions will require a different approach. One solution is to containerize the Maze Solver and run it on a Kubernetes deployment. This is entirely unecessary as currently constructed, but if the solver were able to distribute itself and handle sections of each maze, or the path stack, this could increase the number of workers and theoretically reduce solve time."}
				</p>
				<p className="txt">
					{"The issue would be in how the solve is split up and recombined. Cells interact only with those adjacent to them, but a path can reach anywhere in the maze. One solution could be to split the stack as it grows. A 'distributor/recombiner' instance would distribute the stack sections to be evaluated per node, that could scale as necessary. When their job is complete they return the paths, which are compared with the master list. This solution relies entirely on the premise that removing duplication is not expensive."}
				</p>
				<p className="txt">
					{"A second, and probably superior, solution would be to solve the maze in chunks. The distributor node splits the maze into a number of pieces based on size and sends each to a different worker. Every solution within that chunk is solved and returned to be recombined as pieces of a puzzle. Paths that touch the outer borders of each chunk would be connected to the adjacent chunk's paths. I believe this would be the most useful distrubution scheme. It wouldn't require duplication, and even the recombination could be distributed (or tiered) based on overall size."}
				</p>
				<img className="svg-img" src={SplitSolve} alt="Solve in Chunks" />
				<p className="txt caption">
					{"Each worker solves a section of the maze, distributing the computational load. Sections are then combined, merging paths at break points."}
				</p>
				<p className="txt">
					{"My next step is to build a working demo of this second solution. Thank you for visiting my demo and reading through this write-up."}
				</p>
			</div>
	  </div>
    </>
  );
}

export default Maze;
