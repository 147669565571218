import React from 'react';

import wedding from '../img/art/wedding.jpg'
import quartz from '../img/art/quartz.jpg'
import eagle from '../img/art/eagle.jpg'
import wolfJag from '../img/art/wolf-jag.jpg'
import selfPortrait from '../img/art/self-portrait.jpg'
import parrot from '../img/art/parrot.jpg'
import parrot2 from '../img/art/parrot2.jpg'
import tiger from '../img/art/tiger.jpg'

const Art = () => {
  return (
    <>
		<div className="span2">
			<h3 className="txt">
				Music
			</h3>
			<h5 className="txt">
	 	 		{"I've played cello in various orchestras and small ensemble groups at weddings and other events for most of my life."}
			</h5>
		</div>
		<div>
			<img className="content-img" src={wedding} alt="Cello at wedding" />
		</div>
		<div>
			<img className="content-img" src={quartz} alt="Cello at Quartz Mountain" />
		</div>
		<div className="span2">
			<h3 className="txt">
				Visual Art
			</h3>
			<h5 className="txt">
				{"I've enjoyed working with various visual art mediums over the years. Here are a few examples."}
			 </h5>
		</div>
		<div>
			<img className="content-img" src={eagle} alt="Eagle art" />
		</div>
		<div>
			<img className="content-img" src={wolfJag} alt="Wolf/Jag art" />
		</div>
		<div>
			<img className="content-img" src={selfPortrait} alt="Self Portrait" />
		</div>
		<div>
			<img className="content-img" src={parrot} alt="Parrot art" />
		</div>
		<div>
			<img className="content-img" src={parrot2} alt="Parrot 2 art" />
		</div>
		<div>
			<img className="content-img" src={tiger} alt="Tiger art" />
		</div>
    </>
  );
}

export default Art;
