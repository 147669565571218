import React, { useState } from 'react';
import { auth } from '../firebaseConfig'; // Import your auth instance
import { getAuth } from "firebase/auth";
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useHistory } from 'react-router-dom';

function SignIn() {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const auth = getAuth();
  const user = auth.currentUser;
  if (user) {
    history.push('/diabetes');
  }

  const handleSignIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // Handle successful sign-in (e.g., redirect to a protected page)
      history.push('/diabetes');
    } catch (error) {
      // Handle sign-in errors (e.g., display an error message)
      console.error(error);
    }
  };

  return (
    <div className="diabetes-container">
      <div className="signin">
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <button onClick={handleSignIn}>Sign In</button>
      </div>
    </div>
  );
}

export default SignIn;
