import React from 'react';

import eng3 from '../img/eng3.jpg'
import eng4 from '../img/eng4.jpg'

const Eng = () => {
  return (
    <>
		<div className="span2">
			<h3 className="txt">
				Engineering
			</h3>
			<div className="flex-title">
				<h4 className="txt">
					Optics Fabrication
				</h4>
				<h5 className="label">
					Creo
				</h5>
				<h5 className="label">
					CNC
				</h5>
			</div>
			 <h5>
	  			{"From my time interning in Optimax Systems' R&D department to my current role as the area lead in large windows fabrication at Lockheed Martin, I have had a lot of opportunities to grow as an engineer and problem solver. I work daily with our production team, program engineers, and my engineering team to balance process creation and improvements while meeting schedule requirements within a live production environment. I've been afforded many responsibilities including the following:"}
			</h5>
			 <h5 className="txt listitem">
				{"Fabrication process ownership: improvement of existing optical grinding and polishing processes (+$3M savings), tooling creation, onboarding of capital equipment (+$5M), and expanding shop capabilities through new process creation for prototype products"}
			</h5>
			<h5 className="txt listitem">
				{"Area management and production support for large aperture optics fabrication, working with center management, supervision, operators, and quality engineering to maintain schedule and overall area health"}
			</h5>
			<h5 className="txt listitem">
				{"Management of annual R&D projects developing a new optical metrology technology, overseeing budget, schedule, and technical scope"}
			 </h5>
			<h4 className="txt" style={{marginTop:'3rem'}}>
				Academics
			</h4>
			<h5 className="txt">
				{"As a Technical Entrepreneurship and Management graduate student my coursework and projects ranged from Python bytecode analysis, SQL database design, and Lisp recursive programming to business strategy, market fit analysis, and business plan development."}
			</h5>
		</div>
			<div>
				<img className="content-img" src={eng3} alt="Rochester TA bridge design"/>
			</div>
			<div>
				<img className="content-img" src={eng4} alt="Rochester campus"/>
			</div>
		<div className="span2">
			<h5 className="txt">
				{"As an Mechanical Engineering undergraduate student I was a teaching assistant, and then lead TA, for the entry Mechanical Engineering course at the University of Rochester for 3 years. During that time I helped revamp the major course projects to incorporate new model building and analysis tools. I also worked with local industry and academic researchers on several projects, including:"}
			</h5>
			<h5 className="txt listitem">
				{"Understanding potential improvements of using forced convection to manage temperature gradients in optical glass"}
			</h5>
			<h5 className="txt listitem">
				{"Analysis of liquid damping effects on fusion target stability"}
			</h5>
			<h5 className="txt listitem">
				{"Synthesis of a quick curing concrete that could mimic the material properties of Roman concrete, allowing for model creation and comparison to Roman structure finite element analysis"}
			</h5>
		</div>
    </>
  );
}

export default Eng;
