import React from 'react';

import datavis1 from '../img/datavis1.jpg'
import datavis2 from '../img/datavis2.jpg'
import datavis3 from '../img/datavis3.jpg'

const Datavis = () => {
  return (
    <>
		<div className="span2">
			<h3 className="txt">
				Data Visualization
			</h3>
			<div className="flex-title">
				<h4 className="txt">
					Optics Fabrication
				</h4>
				<h5 className="label">
					Python
				</h5>
				<h5 className="label">
					Matlab
				</h5>
				<h5 className="label">
					Excel
				</h5>
			</div>
			<h5 className="txt">
	  			{"I've created various data input, analysis, and visualization tools during my time in optics fabrication, including:"}
			 </h5>
			 <h5 className="txt listitem">
	  		  	{"A polishing removal simulator for parameter optimization (shown below)"}
			</h5>
			<h5 className="txt listitem">
	  			{"An off-axis parabolic surface error mapping script to compare 3D point cloud data to an ideal surface"}
			</h5>
			<h5 className="txt listitem">
	  			{"Multiple excel input forms, data storage, and graphing tools to handle data management and track machine utilization"}
			</h5>
		</div>
		<div>
			<img className="content-img" src={datavis1} alt="Polisher model 1" />
		</div>
		<div>
			<img className="content-img" src={datavis2} alt="Polisher model 2" />
		</div>
		<div className="span2">
			<div className="flex-title">
				<h4 className="txt">
					Academic Research
				</h4>
				<h5 className="label">
					Python
				</h5>
			</div>
			<h5 className="txt">
				{"I created a geographic data mapping tool for the principle investigator of oceanographic energy flow research to aid in his papers and presentations."}
			</h5>
		</div>
		<div className="span2" style={{width:'auto',maxWidth:'50%'}}>
			<img className="content-img" src={datavis3} alt="Research map output" />
		</div>
    </>
  );
}

export default Datavis;
