import { getAuth } from "firebase/auth";
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from "react";

function Diabetes() {
    const auth = getAuth();
    const user = auth.currentUser;
    const history = useHistory();
    if (!(user)) {
        history.push('/signin');
    } else {
        console.log('User '+user.uid+' is signed in!');
    }

    const [ bloodsugar, setBloodsugar ] = useState(100);
    const [ carbs, setCarbs ] = useState(0);
    const [ units, setUnits ] = useState('');
    const [ trend, setTrend ] = useState(3);

    useEffect(() => {
        const trends = ['trend1','trend2','trend3','trend4','trend5'];
        for (let i = 0; i < trends.length; i++ ) {
            document.getElementById(trends[i]).className = 'trends';
        }
        var units = 0
        const correctionFactor = 40;
        const carbFactor = 10;
        var doCorrection = true;
        document.getElementById('trend'+trend).className = 'trends selected';
        if (trend == 3) { // Flat
            var biasedIdeal = 115;
        } else if (trend == 2) { // Trending Down
            var biasedIdeal = 125;
        } else if (trend == 4) { // Trending Up
            var biasedIdeal = 105;
        } else if (trend == 1) {// Hard Drop
            var doCorrection = false
        } else if (trend == 5) { // Shooting Up
            var biasedIdeal = 90; 
        }
        //const correction = Math.floor ( Math.round( ( bloodsugar - 100 ) / 10 ) * 10 / correctionFactor );
        if (doCorrection) {
            var correction = ( bloodsugar - biasedIdeal ) / correctionFactor;
            if (correction <= 0) {
                correction = 0;
            }
        } else { correction = 0; }
        const carbCorrection = Math.round( carbs / carbFactor * 10 ) / 10;
        units = Math.round( correction + carbCorrection );
        setUnits(units);
    },[bloodsugar,carbs,trend])

    return (
        <div className="diabetes-container">
            <div></div>
            <div className="diabetes">
                <h4> Bloodsugar </h4>
                <input type="number" pattern="[1-9][0-9]*" value={bloodsugar} required onChange={(e)=>setBloodsugar(e.target.value)} />
                <div className="button-row">
                    <button id="trend1" onClick={() => setTrend(1) }>&darr;</button>
                    <button id="trend2" onClick={() => setTrend(2) }>&#8600;</button>
                    <button id="trend3" className="selected" onClick={() => setTrend(3) }>&rarr;</button>
                    <button id="trend4" onClick={() => setTrend(4) }>&#8599;</button>
                    <button id="trend5" onClick={() => setTrend(5) }>&uarr;</button>
                </div>
                <div className="spacer"></div>
                <h4> Carbs </h4>
                <input value={carbs} required onChange={(e)=>setCarbs(Math.abs(e.target.value))} />
                <div className="spacer"></div>
                <h4> Units </h4>
                <input className="result" value={units} readOnly />
            </div>
            <div></div>
        </div>
    );
}

export default Diabetes;
