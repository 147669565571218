import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyCTXMcA2xk_5xiz1khbD2ogZnT-AZ93iAk",
    authDomain: "mclinninfo.firebaseapp.com",
    projectId: "mclinninfo",
    storageBucket: "mclinninfo.appspot.com",
    messagingSenderId: "289470724205",
    appId: "1:289470724205:web:4c66bf5a4c16346035e764",
    measurementId: "G-LBEL946GNV"  
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const firestore = getFirestore(app);

export default { app };
