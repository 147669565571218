import React from 'react'
import { Route, Switch, Link } from 'react-router-dom'
import * as ROUTES from './constants/routes.js'

import logo from './img/logo.jpg'

import Welcome from './components/welcome.js'
import Art from './components/art.js'
import Datavis from './components/datavis.js'
import Design from './components/design.js'
import Eng from './components/eng.js'
import Maze from './components/maze.js'
import Diabetes from './components/diabetes.js'
import SignIn from './components/signin.js'

const App = () => {
	return (
		<Switch>
			<Route exact path={ROUTES.DIABETES}>
				<Diabetes />
			</Route>
			<Route exact path={ROUTES.SIGNIN}>
				<SignIn />
			</Route>
			<Route>
				<div className="container">
					<nav>
						<div className="nav-logo">
							<Link to={ROUTES.HOME}>
								<img id="logo" src={logo} alt="Jonathan McLinn" />
							</Link>
						</div>
						<h6 id="eng" className="nav-item"><Link to={ROUTES.ENG}>Engineering</Link></h6>
						<h6 id="data" className="nav-item"><Link to={ROUTES.DATAVIS}>Visualization</Link></h6>
						<h6 id="des" className="nav-item"><Link to={ROUTES.DESIGN}>Design</Link></h6>
						<h6 id="art" className="nav-item"><Link to={ROUTES.ART}>Art & Music</Link></h6>

						<Route exact path={ROUTES.HOME}>
							<><div className="button" style={{gridColumn:'span 6',marginTop:'2em',marginBottom:'0'}}>
								<h6 style={{padding:"0"}}>
									<Link to={ROUTES.MAZE} style={{display:'inline',color:'#0af',padding:"1em"}}>{"Maze Demo"}</Link>
								</h6>
							</div></>
						</Route>
						
					</nav>
					<main>
						<Switch>
							<Route exact path={ROUTES.HOME}>
								<Welcome />
							</Route>
							<Route path={ROUTES.ART}>
								<Art />
							</Route>
							<Route path={ROUTES.DATAVIS}>
								<Datavis />
							</Route>
							<Route path={ROUTES.DESIGN}>
								<Design />
							</Route>
							<Route path={ROUTES.ENG}>
								<Eng />
							</Route>
							<Route path={ROUTES.MAZE}>
								<Maze />
							</Route>
						</Switch>
					</main>
					<footer>
						<a href="mailto:jonathan@mclinn.info"><p className="link">jonathan@mclinn.info</p></a>
						<p>&copy; Jonathan McLinn, {new Date().getFullYear()}</p>
					</footer>
				</div>
			</Route>
		</Switch>
	)
}

//const App = () => {
//  return (
//	<Switch>
//		<Route exact path={ROUTES.DIABETES} >
//		<Route component={ Container } />
//	</Switch>
//  )
//}

export default App;
