import React from 'react';

import me from '../img/me.jpg'

const Art = () => {
  return (
    <>
		
		<div>
			<img id="main-img" src={me} alt="Me"/>
		</div>
		<div>
			<h4 className="txt">
				Welcome,
			</h4>
	
			<h5 className="txt">
	  			{"I like to stretch my knowledge through problem solving and discovery of inventive solutions."}
			</h5>
			<h5 className="txt">
				{"Please see my various technical and artistic experiences that have helped me reach where I am today."}
			</h5>
			<h5 className="txt">
				{"- Jonathan"}
			</h5>
		</div>
    </>
  );
}

export default Art;
