import React from 'react';

import mywave1 from '../img/mywave1.jpg'
import mywave2 from '../img/mywave2.jpg'
import thunder1 from '../img/thunder1.jpg'
import thunder2 from '../img/thunder2.jpg'

const Design = () => {
  return (
    <>
		<div className="span2">
			<h3 className="txt">
				Web Design
			</h3>
			<div className="flex-title">
				<h4 className="txt">
					MyWave
				</h4>
				<h5 className="label">
					React
				</h5>
				<h5 className="label">
					JS
				</h5>
				<h5 className="label">
					CSS
				</h5>
				<h5 className="label">
					HTML
				</h5>
			</div>
			<h5 className="txt">
			  {"MyWave is a currently in development live event management and ticketing platform. It has taken several forms over the years, starting in 2013 as basic html/css/js website with a Django backend. In 2020, I then completely rebuilt it from the ground up using React and Google's Firebase cloud platform for backend processes, authentication, database, and deployment. MyWave has been the primary vehicle for me to learn web programming, which I've enjoyed greatly as I've worked through all the development pains and bugs. I've also been able to apply tools and knowledge from my other engineering roles and graduate courses to develop and improve the focus, design, process flows, etc. of MyWave."}
			 </h5>
		</div>
		<div>
			<img className="content-img" src={mywave1} alt="Mywave welcome" />
		</div>
		<div>
			<img className="content-img" src={mywave2} alt="Mywave event creator" />
		</div>
		<div className="span2">
			<div className="flex-title">
				<h4 className="txt">
					Thunder Op-Ed
				</h4>
				<h5 className="label">
					Jekyll
				</h5>
				<h5 className="label">
					CSS
				</h5>
				<h5 className="label">
					HTML
				</h5>
			</div>
			<h5 className="txt">
				{"A few friends and I started an, albeit short-lived, OKC Thunder blog. I created the site using Github Pages and Jekyll and set my friends up to be able to easily push new articles to the site. I'm particularly proud of the "} <a style={{display:'inline',color:'#0af'}} href="http://thunderoped.com/schedule/" target="_blank" rel="noopener noreferrer">calendar</a>{" design, which changed from the channel and gametime to the final score once updated postgame."}
			</h5>
		</div>
		<div>
			<img className="content-img" src={thunder1} alt="Thunder OpEd home" />
		</div>
		<div>
			<img className="content-img" src={thunder2} alt="Thunder OpEd calendar" />
		</div>
		<div className="span2">
			<h4 className="txt">
				Wedding Website
			</h4>
			<h5 className="txt">
				{"It felt more personal to create my own wedding "}<a style={{display:'inline',color:'#0af'}} href="http://www.emilyjonathan.com/" target="_blank" rel="noopener noreferrer">website</a>{". I put together a simple, single page site so our guests could easily access important details and information."}
			</h5>
		</div>
    </>
  );
}

export default Design;
