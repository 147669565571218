// constants - routes.js

export const HOME = '/';
export const ART = '/art';
export const DATAVIS ='/datavis';
export const DESIGN = '/design';
export const ENG = '/eng';
export const MAZE = '/maze';
// Diabetes
export const SIGNIN = '/signin';
export const DIABETES = '/diabetes';
